import $ from "jquery";

const imgs = $("#gallery img");
const fullPage = $("#fullpage");

imgs.each((_idx, image) => {
    const img = image as HTMLImageElement
	img.addEventListener("click", () => {
		fullPage.css({ "background-image": "url(" + img.src + ")" });
		fullPage.removeClass("hidden");
	});
});

// TODO: Rotating reviews, loaded from server
// const quoteTemplate = ({ title, description, author, authorTitle }) => `
// <figure class="flex flex-col items-center justify-center p-8 text-center border-b rounded-t-lg md:rounded-t-none md:rounded-tl-lg md:border-r bg-gray-800 border-gray-700">
//     <blockquote class="max-w-2xl mx-auto mb-4 lg:mb-8 text-gray-400">
//         <h3 class="text-lg font-semibold text-white">${title}</h3>
//         <p class="my-4">${description}</p>
//     </blockquote>
//     <figcaption class="flex items-center justify-center space-x-3">
//         <img class="rounded-full w-9 h-9" src="${author.img64}" alt="profile picture">
//         <div class="space-y-0.5 font-medium text-white text-left">
//             <div>${author.fname} ${author.lname}</div>
//             <div class="text-sm text-gray-400">${authorTitle}</div>
//         </div>
//     </figcaption>
// </figure>
// `;

// console.log(quoteTemplate);
// $(() => {
// 	$("[data-review]").text("Loading review");
// });
